import { Navigate } from '@remix-run/react';
import { useLayoutEffect } from 'react';

import {
  REDIRECT_CUSTOM_TARGET_MAP,
  useUserRedirect,
} from '../../hooks/useRedirection';
import { GlobalLoading } from '../GlobalLoading';
import { useMyOrganizer } from '../Organization';
import { useIsUserLoaded, useUser } from '../UserContext';
import {
  buildSearchParamsWithRedirectToAsString,
  useRedirectTo,
} from './hooks';

export function RequireNonUser(props: {
  children: React.ReactNode;
}): JSX.Element {
  useUser({ init: true });
  const execRules = useUserRedirect();
  const isUserLoaded = useIsUserLoaded();
  const redirectTo = useRedirectTo();

  useLayoutEffect(() => {
    if (!isUserLoaded) return;

    execRules([
      { kind: 'noUser', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
      { kind: 'guest', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
      {
        kind: 'final',
        target:
          redirectTo === 'noop'
            ? REDIRECT_CUSTOM_TARGET_MAP.noop
            : redirectTo || '/home',
      },
    ]);
  }, [isUserLoaded, execRules, redirectTo]);

  if (!isUserLoaded) {
    return <GlobalLoading debug='' />;
  }

  return <>{props.children}</>;
}

export function RequireActivation(props: {
  children?: React.ReactNode;
}): JSX.Element {
  const organizer = useMyOrganizer();

  if (organizer && !organizer.activated) {
    return (
      <Navigate
        to={{
          pathname: '/user-active',
          search: buildSearchParamsWithRedirectToAsString(window.location.href),
        }}
      />
    );
  }

  return <>{props.children}</>;
}
